
import {
  computed,
  ComputedRef,
  defineComponent,
  inject,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRefs,
  watch,
} from "vue";
import TextButton from "@/components/atomics/TextButton.vue";

import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";
import MenuOptionPopup from "@/components/atomics/MenuOptionPopup.vue";
/// Component for preview pdf file
import PdfPreviewVars from "@/components/parts/PdfPreviewVars.vue";
import HeaderTitleBar from "@/components/parts/HeaderTitleBar.vue";

import { ContractRepository, RepositoryFactory } from "@/lib/https";
import { mapMutations } from "vuex";

import { useRoute, useRouter } from "vue-router";
import { getFilePathFromUrl } from "@/lib/utility/stringUtil";
import useSignFunctions from "@/lib/compositional-logic/useSignFunctions";
import storage from "@/lib/storage/storageHelper";
///Library for preview docx file.
import { renderAsync } from "docx-preview";

// const docx2html = require("docx2html");
import { XlsxRead, XlsxTable, XlsxSheets } from "vue3-xlsx";
import Tabs from "@/components/atomics/Tabs/Tabs.vue";

const __default__ = defineComponent({
  name: "ContractDetailReadonly",
  components: {
    TextButton,
    PdfPreviewVars,
    AfterLoginPageLayout,
    MenuOptionPopup,
    HeaderTitleBar,
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    Tabs,
  },
  setup() {
    const state = reactive({
      isMounted: false,
      folderId: "",
      folderTitle: "",
      file: new Blob(),
      selectedSheet: 0,
      sheets: [] as any[],
    });

    const fileState = reactive({
      page: 1,
      numPages: 1,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      selectedFile: {} as any,
    });

    const fileScaleMobile = ref(1.0);
    const isMobile = inject("isMobile") as ComputedRef;
    const route = useRoute();
    const router = useRouter();

    const { getContractFullInformation } = contractDetailMethods();
    const { getAccessCode } = useSignFunctions();

    const localStorage = storage.getLocalStorage();
    const sessionId = localStorage.get("sessionId");
    const userToken = localStorage.get("userToken");
    // Check for document has already loaded and ready for rendering.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onLoaded = ref(null) as any;

    const isPdf = computed(
      () => fileState.selectedFile.fileType == "application/pdf"
    );

    const isDocx = computed(
      () =>
        fileState.selectedFile.fileType ==
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    );

    const isXlsx = computed(
      () =>
        fileState.selectedFile.fileType ==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );

    const navigateToFolder = () => {
      const backEntry = {
        name: "Folder",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any;
      if (state.folderId) {
        backEntry.query = {
          folderId: state.folderId,
          folderTitle: state.folderTitle,
        };
      }
      router.push(backEntry);
    };

    // At the time the main content of file preview is rendered, we should pass the [isShownScrollBar] function
    // to onLoaded to resize the header and the body to match with each others when the document is displayed.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isShownContent = (isShownScrollBar: any) => {
      onLoaded.value = isShownScrollBar;
      return state.isMounted;
    };

    const resizePdf = () => {
      let sheet = document.getElementById("sheet") as HTMLElement;
      let contentWidth = sheet.clientWidth;
      if (isMobile.value) {
        let maxWidth = document.body.clientWidth;
        fileScaleMobile.value =
          maxWidth > contentWidth ? 1 : maxWidth / contentWidth;
        // sheet.style.height = sheet.clientHeight * fileScaleMobile.value + "px";
      }
    };

    const resizeDocx = () => {
      let container = document
        .getElementsByClassName("docx-wrapper")
        .item(0) as HTMLElement;
      let sheet = document.getElementById("sheet") as HTMLElement;
      let header = document.getElementById("header") as HTMLDivElement;
      let topPart = document.querySelector(
        ".left-part > .top-part"
      ) as HTMLDivElement;
      let contentWidth = container.clientWidth;
      if (isMobile.value) {
        let maxWidth = document.body.clientWidth;
        fileScaleMobile.value =
          maxWidth > contentWidth ? 1 : maxWidth / contentWidth;
        topPart.style.minWidth = maxWidth + "px";
        sheet.style.height =
          container.clientHeight * fileScaleMobile.value + "px";
      } else {
        // Calculate width of some container for layout
        fileScaleMobile.value = 1;
        sheet.style.minWidth = contentWidth + 10 + "px";
        header.style.minWidth = contentWidth + 10 + "px";
        topPart.style.minWidth = contentWidth + 30 + "px";
      }
    };

    const resizeXlsx = () => {
      let container = document
        .getElementsByClassName("xlsx-wrapper")
        .item(0) as HTMLElement;
      let sheet = document.getElementById("sheet") as HTMLElement;
      if (isMobile.value) {
        let maxWidth = document.body.clientWidth;
        let scale = 2;
        fileScaleMobile.value = 1 / scale;
        sheet.style.width = scale * maxWidth + "px";
        sheet.style.height =
          container.clientHeight * fileScaleMobile.value + "px";
      } else {
        fileScaleMobile.value = 1;
        sheet.style.width = "auto";
      }
    };

    const resize = () => {
      if (isPdf.value) {
        resizePdf();
      } else if (isDocx.value) {
        resizeDocx();
      } else if (isXlsx.value) {
        resizeXlsx();
      }
    };
    onMounted(async () => {
      try {
        state.isMounted = true;
        const accessCode = getAccessCode(route.params.id as string);
        let contractFullInformation = await getContractFullInformation(
          route.params.id as string,
          accessCode
        );

        state.folderId = contractFullInformation.folderId;
        state.folderTitle = contractFullInformation.folderTitle;

        fileState.selectedFile = {
          contractId: contractFullInformation.id,
          fileName: contractFullInformation.title,
          filePath: contractFullInformation.filePath,
          fileType: contractFullInformation.contentType,
          id: contractFullInformation.id,
        };

        if (isDocx.value) {
          fetch(getFilePathFromUrl(fileState.selectedFile.filePath), {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": process.env.VUE_APP_URL as string,
              Authorization: `Bearer ${userToken}`,
              Session: sessionId,
            },
          })
            .then((r) => {
              return r.blob();
            })
            .then((blob) => {
              return renderAsync(
                blob,
                document.getElementById("docx") as HTMLElement,
                undefined,
                {
                  inWrapper: true,
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } as any
              );
            })
            .then(() => {
              resizeDocx();
              onLoaded.value();
            });
        }
        if (isXlsx.value) {
          fetch(getFilePathFromUrl(fileState.selectedFile.filePath), {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers": process.env.VUE_APP_URL as string,
              Authorization: `Bearer ${userToken}`,
              Session: sessionId,
            },
          })
            .then((r) => {
              return r.blob();
            })
            .then((blob) => {
              state.file = blob;
              resizeXlsx();
              onLoaded.value();
            });
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        window.addEventListener("resize", resize);
      } catch (e: any) {
        const errorMessage = e?.data?.message || "";
        router.push({
          name: "ErrorPage",
          query: {
            error: errorMessage,
          },
        });
      }
    });
    onUnmounted(() => window.removeEventListener("resize", resize));
    return {
      ...toRefs(state),
      ...toRefs(fileState),
      getFilePathFromUrl,
      isMobile,
      isPdf,
      isDocx,
      isXlsx,
      fileScaleMobile,
      navigateToFolder,
      isShownContent,
    };
  },
  methods: {
    ...mapMutations("popup", ["setPopup", "unsetPopup"]),
    // general function
    download(url: string) {
      const link = document.createElement("a");
      link.href = getFilePathFromUrl(url);
      link.target = "_blank";
      link.click();
    },

    // Modified PDF view function
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onReady(file: any, pageNums: number) {
      this.numPages = pageNums;
      file.pageNums = pageNums;
      file.loadDone = true;
    },
    increasePage() {this.fileScaleMobile = 1;
      if (this.page < this.numPages) {
        this.page = this.page + 1;
      }
    },
    decreasePage() {
      this.fileScaleMobile = 1;
      if (this.page > 1) {
        this.page = this.page - 1;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setPage(e: any) {
      if (!this.page && !this.selectedFile.pageNums) {
        this.selectedFile.currentPage = 1;
        return;
      }
      const page = parseInt(e.target.value, 10);
      if (page <= 0) {
        this.page = 1;
        e.target.value = 1;
        return;
      } else if (page >= (this.selectedFile.pageNums || 0)) {
        this.page = this.selectedFile.pageNums;
        e.target.value = this.selectedFile.pageNums;
        return;
      } else if (page) this.page = page;
      if (e.data != null) {
        this.page = parseInt(e.data);
      }
    },
  },
});

const contractDetailMethods = () => {
  const { getGuestContractInformation, getContractFullInformation } =
    RepositoryFactory.getRepository<ContractRepository>(ContractRepository);

  return {
    getGuestContractInformation,
    getContractFullInformation,
  };
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "4a6e9380": (_ctx.fileScaleMobile)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__